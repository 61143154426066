import React, { SyntheticEvent, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useLocation, Outlet } from "react-router-dom";

import { colors, fonts, SLink, Wrap } from "../common/Styles";
import logo from "../images/logo.png";
import dcr from "../images/massdcr.png";

const getIsMobile = () =>
    Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
    ) < 900;

const Sep = styled.span`
    border-right: 1px solid #fff;
`;

const NavComp = ({
    moveBar,
    here,
    left,
    width,
    loaded,
    isMobile,
}: {
    moveBar: (e: SyntheticEvent | undefined) => void;
    here: string;
    left: number;
    width: number;
    loaded: boolean;
    isMobile: boolean;
}) => (
    <NavContainer className={isMobile ? `mobile` : `desktop`}>
        <Nav>
            <Bar
                isMobile={isMobile}
                style={{
                    left: `${left}px`,
                    width: `${width}px`,
                    transition: loaded ? `all 0.5s ease` : ``,
                }}
            />
            <NavItem {...{ isMobile }} id="about" onClick={moveBar} className={here === "/about" ? "active" : ""} to="/about">
                About
            </NavItem>
            <Sep />
            <NavItem
                {...{ isMobile }}
                id="events"
                onClick={moveBar}
                className={here === "/" ? "active" : ""}
                to="/"
            >
                Events
            </NavItem>
            <Sep />
            <NavItem
                {...{ isMobile }}
                id="gallery"
                onClick={moveBar}
                className={here === "/gallery" ? "active" : ""}
                to="/gallery"
            >
                Gallery
            </NavItem>
            <Sep />
            <NavItem
                {...{ isMobile }}
                id="news"
                onClick={moveBar}
                className={here === "/news" ? "active" : ""}
                to="/news"
            >
                News
            </NavItem>
            <Sep />
            <NavItem
                {...{ isMobile }}
                id="partners"
                onClick={moveBar}
                className={here === "/partners" ? "active" : ""}
                to="/partners"
            >
                Partners
            </NavItem>
        </Nav>
    </NavContainer>
);

const Top = styled.div`
    background: ${colors.darkGrey};
    color: #fff;
    font-weight: 600;
    vertical-align: bottom;
    padding: 10px 0;
    position: relative;
`;

const InnerTop = styled.div`
    max-width: 1400px;
    margin: 0 auto;
`;

const Backdrop = styled.div`
    border-bottom: 1px solid ${colors.darkGrey};
    overflow: hidden;
    box-shadow: 0px 1px 10px 0 #ccc;
`;

const NavContainer = styled.div`
    &.desktop {
        background: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-40%,-50%);
        max-width: 700px;
        width: calc(100% - 600px);
        background: ${colors.darkGrey};
    }
`;

const Nav = styled.div`
    height: 35px;
    display: flex;
    position: relative;
    justify-content: space-around;
    padding: 8px 0;
    max-width: 1400px;
    margin: 0 auto;
`;

const NavItem = styled(SLink)`
    color: ${({ isMobile }: { isMobile: boolean }) => (isMobile ? colors.darkGrey : `white`)};
    font-size: ${fonts.medium};
    font-weight: 700;
    margin: 0 5px;
    cursor: pointer;
    ${({ isMobile }: { isMobile: boolean }) => (isMobile ? `` : `text-transform: uppercase`)};

    ${({ isMobile }: { isMobile: boolean }) =>
        isMobile
            ? ``
            : `&:hover {
    color: ${colors.lightGrey} !important;
  }`}

    &.active {
        ${({ isMobile }: { isMobile: boolean }) => (isMobile ? `` : `color: ${colors.lightGrey} !important;`)}
    }
`;

const Bar = styled.div`
    position: absolute;
    bottom: 1px;
    height: 5px;
    border-radius: 8px;
    width: 30px;
    background: ${colors.red};
    ${({ isMobile }: { isMobile: boolean }) => (isMobile ? `` : `display: none;`)}
`;

const Friends = styled.div`
    font-size: ${fonts.large};
    display: inline-block;
    margin: 0 0 0 10px;
    vertical-align: middle;
    &.mobile {
        font-size: ${fonts.medium};
    }
`;

const elemMap = {
    "/": "events",
    "/about": "about",
    "/gallery": "gallery",
    "/news": "news",
    "/partners": "partners",
};

const Logo = styled.img`
    max-height: 69px;
    margin: 0 10px 0;
    vertical-align: middle;

    &.right {
        float: right;
    }
`;

function Header() {
    const location = useLocation();
    const here = location.pathname as "/" | "/about" | "/gallery" | "/news" | "/partners";
    const getNewLoc = (elem: HTMLElement) => {
        const parentX = Math.trunc(elem.parentElement?.getBoundingClientRect().x || 0);
        const elemX = Math.trunc(elem.getBoundingClientRect().x || 0);
        return { left: elemX - parentX, width: elem.clientWidth };
    };

    const [left, setLeft] = useState(0);
    const [width, setWidth] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [isMobile, setIsMobile] = React.useState<boolean>(getIsMobile());

    let timer: ReturnType<typeof setTimeout>;

    const setTimer = () => {
        clearTimeout(timer);
        timer = setTimeout(() => setIsMobile(getIsMobile()), 500);
    };

    useEffect(() => {
        setIsMobile(getIsMobile());
        window.addEventListener("resize", setTimer);
        // **** remove ****
        setLoaded(true);
    }, []);

    const moveBar = (e: SyntheticEvent | undefined) => {
        let elem;
        if (e) {
            elem = e.currentTarget;
        } else {
            elem = document.getElementById(elemMap[here]) as HTMLElement;
        }

        const newLoc = getNewLoc(elem as HTMLElement);
        setLeft(newLoc.left);
        setWidth(newLoc.width);
    };

    let timeout: undefined | number;
    const resize = () => {
        clearTimeout(timeout);
        timeout = setTimeout(moveBar, 250);
    };

    useEffect(() => {
        if (window.location.pathname !== "/") {
            moveBar(undefined);
        }
        if (!loaded) {
            setTimeout(() => {
                setLoaded(true);
            }, 500);
        }
    }, []);

    useEffect(() => {
        window.addEventListener("resize", resize);
    });

    return (
        <>
            <Backdrop>
                <Top>
                    <InnerTop>
                        <Logo src={logo} />
                        <Friends className={isMobile ? `mobile` : `desktop`}>
                            <span>Friends of</span>
                            <br />
                            <span>the Dighton</span>
                            <br />
                            <span>Rock Museum</span>
                        </Friends>
                        {!isMobile && <NavComp {...{ moveBar, left, width, here, loaded, isMobile }} />}
                        <Logo src={dcr} className="right" />
                    </InnerTop>
                </Top>
                {/* @ts-ignore */}
                {isMobile && <NavComp {...{ moveBar, left, width, here, loaded, isMobile }} />}
            </Backdrop>
            <Wrap>
                <Outlet />
            </Wrap>
        </>
    );
}
export default Header;
