/* eslint-disable */
/* tslint disable */

import React, { useEffect } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InnerHTML from "dangerously-set-html-content";
import styled from "@emotion/styled";
import GD from "../images/gd.png";
import StoryHourImg from "../images/storyhour.png";
import Yoga from "../images/yoga.png";
import { AddToCalendarButton } from "add-to-calendar-button-react";

const Text = styled(Typography)`
  p:first-child {
    font-weight: bold;
  }
`;

const getIsMobile = () =>
  Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  ) < 900;

const events = [
  {
    i: StoryHourImg,
    n: `Family Craft Time With Erica Grady`,
    d: `Second Saturday July - August`,
    de: `<p class="title">Family Craft Time</p>
    <p>Second Saturday of June-August 11:00am—12:00pm</p><img style="display: block;max-width: 300px;" src=${StoryHourImg} /><p>All ages are welcome. No registration required.</p>
    Berkley Public Library presents a family-friendly outdoor Craft Time at Dighton Rock State Park focused on the history of Dighton Rock including stories, music, and activities.</p><p> Presented by Erica Grady, Berkley Library Children’s Librarian. 
    Program will take place outdoors. Consider sunscreen, drinking water, and bug spray. Bring a blanket or lawn chair. During inclement weather, we will move into the Museum.</p><p>Program sponsored by the Berkley Public Library, the Friends of the Dighton Rock Museum, and the Massachusetts Department of Conservation & Recreation.</p>
    `,
  },
  {
    i: Yoga,
    n: `Sunday Morning Yoga`,
    cd: `2024-04-16`,
    d: `Every Sunday in May`,

    de: `<p>Every Sunday in May 10:00am—11:00am</p><img style="display: block;max-width: 300px;" src=${Yoga} /><p>All ages are welcome. No registration required. Free to the public.</p>
    <p>Weather permitting.</p><p>This program is supported in part by a grant from the Berkley Cultural Council, a local agency which is supported by the Mass Cultural Council, a state agency.</p>`,
  },
  {
    n: `Gold In Golden New England`,
    cd: `2024-04-14`,
    d: `April 14, 2024`,
    st: `13:00`,
    et: `14:30`,
    de: `<p>For ages 8+ years. 1:00 pm—2:30 pm.</p><p>Join Jim Pecora for this lecture which explores all aspects of that amazing metal, gold: 
    noun, adjective, metaphorically, and yes economically. Pecora will share with you the scams, myths, and facts about the most sought after metal on earth..</p><p>This program is supported in part by a grant from the Berkley Cultural Council, a local agency which is supported by the Mass Cultural Council, a state agency.</p>`,
  },
  {
    n: `Taunton Municipal Lighting Plant`,
    cd: `2024-05-19`,
    d: `May 19, 2024`,
    st: `13:00`,
    et: `14:30`,
    de: `<p>Ages 6 and up. 1:00 pm—2:30 pm.</p><p>Kimberly Holmes from the taunton Municipal Lighting Plant will present the basics of electrical
generation and shore programs available to local residents.</p>`,
  },
  {
    n: `Tetiquet To The Sea: A History Of The Taunton River`,
    cd: `2024-06-23`,
    d: `June 23, 2024`,
    st: `13:00`,
    et: `14:30`,
    de: `<p>For all ages. 1:00 pm-2:30 pm.</p><p>Dr. William Hanna speaks about the recently published book, wiich includes historical manuscripts, 
    printed material and photographs and illustrates the 
    history and present day vitality of the Taunton River.</p>`,
  },
  {
    n: `Ancient Odysseys To North America`,
    cd: `2024-07-14`,
    d: `July 14, 2024`,
    st: `13:00`,
    et: `14:30`,
    de: `<p>For ages 8 and up. 1:00 pm-2:30 pm.</p><p>This talk by Dr. Crystal Lubinsky will link two accounts, one Metiterranean and the other Scandanavian, with the 
    theories of who inscribed Dighton's Rock.</p>`,
  },
  {
    n: `Nature Hike`,
    cd: `2024-08-11`,
    d: `August 11, 2024`,
    st: `11:00`,
    et: `12:30`,
    de: `<p>All ages welcome. 11:00 am-12:30 pm.</p><p>Come on a nature hike with the Massachusetts Department of 
    Conservations's regional interpreter Simon Schreier.</p>`,
  },
  {
    n: `PFAS Contamination Of The Taunton River`,
    cd: `2024-09-08`,
    d: `September 8, 2024`,
    st: `13:00`,
    et: `14:30`,
    de: `<p>For ages 8 and up. 1:00 pm-2:30 pm.</p><p>Dr. David Taylor of Roger Williams University shares his most recent research about the Taunton River.</p>`,
  },
  {
    n: `Horse Drawn Staegcoach Travel In New England`,
    cd: `2024-10-13`,
    d: `October 13, 2024`,
    st: `13:00`,
    et: `14:30`,
    de: `<p>For ages 8 and up. 1:00 pm-2:30 pm.</p><p>Before there were planes, trains and automobiles, long distance travel on land in the late 18th and
     19th centuries was done by horse drawn stagecoaches. This presentation
    will discuss the pleasure and hazards of this form of travel. Local 
    stagecoach highways and taverns in the eastern parts of New England will also be revealed along with stories 
    that may shock you. You won't be disappointed!</p>`,
  },
].sort((a, b) =>
  new Date(a.d) > new Date(b.d) || !new Date(b.d).valueOf() ? 1 : -1
);

const SideWrap = styled.div`
  padding: 20px;
`;

const Spacer = styled.div`
  height: 30px;
  width: 100%;
`;

const InlineImg = styled.img`
  height: 100%;
`;

const SideContent = () => (
  <SideWrap>
    <a
      href="https://goo.gl/maps/KZL36qshZB9kFqgf8"
      rel="noreferrer"
      target="_blank"
    >
      <img
        style={{
          height: `55px`,
          margin: `0 20px 0 0`,
          verticalAlign: `middle`,
        }}
        alt="get directions"
        src={GD}
      />
      <span style={{ verticalAlign: `middle` }}>Get Directions</span>
    </a>
    <p>3rd Ave, Berkley, MA 02779</p>
    {["/", "/events"].includes(window.location.pathname) && (
      <InnerHTML
        html={`<a class="weatherwidget-io" href="https://forecast7.com/en/41d85n71d08/berkley/?unit=us" data-label_1="BERKLEY" data-label_2="WEATHER" data-theme="pure" >BERKLEY WEATHER</a>
<script>
!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src='https://weatherwidget.io/js/widget.min.js';fjs.parentNode.insertBefore(js,fjs);}}(document,'script','weatherwidget-io-js');
</script>`}
      />
    )}
  </SideWrap>
);

const formatDate = (d: string) =>
  new Date(d).toLocaleString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

const Events = () => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [isMobile, setIsMobile] = React.useState<boolean>(getIsMobile());

  let timer: ReturnType<typeof setTimeout>;

  const setTimer = () => {
    clearTimeout(timer);
    timer = setTimeout(() => setIsMobile(getIsMobile()), 500);
  };

  useEffect(() => {
    setIsMobile(getIsMobile());
    window.addEventListener("resize", setTimer);
  }, []);

  useEffect(() => {
    // @ts-ignore
    let __weatherwidget_init: Function = window["__weatherwidget_init"];
    if (typeof __weatherwidget_init === "function") {
      //  eslint-disable-next-line
      __weatherwidget_init();
    }
  }, [window.location.pathname]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <>
      <div
        style={{
          display: `inline-block`,
          verticalAlign: `top`,
          width: isMobile ? `100%` : `calc(100% - 300px)`,
        }}
      >
        <Spacer />
        {events.map((fd, i) => (
          <Accordion
            key={fd.n}
            expanded={expanded === `panel${i}`}
            onChange={handleChange(`panel${i}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography
                sx={{
                  width: "10%",
                  minWidth: "90px",
                  height: `40px`,
                  flexShrink: 0,
                  marginRight: `10px`,
                  color: `#0277BD`,
                  fontWeight: `700`,
                }}
              >
                {fd.i ? <InlineImg src={fd.i} /> : null}
              </Typography>
              <Typography
                sx={{
                  width: "33%",
                  height: `40px`,
                  flexShrink: 0,
                  marginRight: `10px`,
                  color: `#0277BD`,
                  fontWeight: `700`,
                }}
              >
                {new Date(fd.d).valueOf() ? formatDate(fd.d) : fd.d}
              </Typography>
              <Typography sx={{ color: `#0277BD`, fontWeight: `700` }}>
                {fd.n}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Text
                dangerouslySetInnerHTML={{ __html: fd.de }}
                sx={{ padding: `6px 8px` }}
              />

              {!!new Date(fd.d).valueOf() && (
                <AddToCalendarButton
                  name={fd.n}
                  options={["Apple", "Google", `Microsoft365`, `Outlook.com`]}
                  location="Dighton Rock Museum"
                  startDate={fd.cd}
                  endDate={fd.cd}
                  startTime={fd.st}
                  endTime={fd.et}
                  timeZone="America/New_York"
                ></AddToCalendarButton>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      <div
        style={{
          display: isMobile ? `block` : `inline-block`,
          minWidth: `300px`,
        }}
      >
        <SideContent />
      </div>
    </>
  );
};

export default Events;
