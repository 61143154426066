import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import Events from "./components/Events";
import Landing from "./components/Landing";
import ComingSoon from "./components/ComingSoon";
import Gallery from "./components/Gallery";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Header />}>
        <Route index element={<Events />} />
          <Route path="about" element={<Landing />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="news" element={<ComingSoon />} />
          <Route path="partners" element={<ComingSoon />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
