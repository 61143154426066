import styled from "@emotion/styled";
import React from "react";

const Centered = styled.div`
  height: 100%;
  position: relative;
`;

const Message = styled.div`
  position: absolute;
  font-size: 28px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ComingSoon = () => (
  <Centered>
    <Message>Coming Soon</Message>
  </Centered>
);

export default ComingSoon;
