import { Swiper, SwiperSlide } from "swiper/react";
import { Zoom, Navigation, Pagination } from "swiper";
import styled from "@emotion/styled";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/navigation";
import "swiper/css/pagination";

// Import Swiper styles
import "swiper/css";
import img0 from "../images/carousel/oldDR_pix (1)-min.jpg";
import img1 from "../images/carousel/oldDR_pix (2)-min.jpg";
import img2 from "../images/carousel/oldDR_pix (3)-min.jpg";
import img3 from "../images/carousel/oldDR_pix (4)-min.jpg";
import img4 from "../images/carousel/oldDR_pix (5)-min.jpg";
import img5 from "../images/carousel/oldDR_pix (6)-min.jpg";
import img6 from "../images/carousel/oldDR_pix (7)-min.jpg";
import img7 from "../images/carousel/oldDR_pix (8)-min.jpg";
import img9 from "../images/carousel/oldDR_pix (10)-min.jpg";
import img10 from "../images/carousel/oldDR_pix (11)-min.jpg";
import img11 from "../images/carousel/oldDR_pix (12)-min.jpg";
import img12 from "../images/carousel/oldDR_pix (13)-min.jpg";
import img13 from "../images/carousel/oldDR_pix (14)-min.jpg";
import img14 from "../images/carousel/oldDR_pix (15)-min.jpg";
import img15 from "../images/carousel/oldDR_pix (16)-min.jpg";
import img16 from "../images/carousel/oldDR_pix (17)-min.jpg";
import img17 from "../images/carousel/oldDR_pix (18)-min.jpg";

const Wrapper = styled.div`
  background-color: #4b6f44;
`;

const images = [
  img0,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
];

const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`;

const Gallery = () => (
    <Wrapper>
  <Swiper
    zoom
    navigation
    pagination={{
      clickable: true,
    }}
    modules={[Zoom, Navigation, Pagination]}
    className="mySwiper">
    {images.map((i) => (
      <SwiperSlide>
        <div className="swiper-zoom-container">
          <Img alt="" src={i} />
        </div>
      </SwiperSlide>
    ))}
  </Swiper></Wrapper>
);

export default Gallery;
