import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

const headHeight = 96;

export const fonts = {
  title: '35px',
  large: '20px',
  medium: '18px',
  small: '15px',
};

export const colors = {
  green: '#016765',
  darkGrey: '#2b2b2b',
  lightGrey: `#DEDEDE`,
  red: `rgb(254,45,45)`
};

export const Para = styled.p`
  font-size: ${fonts.medium};
  font-weight: 500;
  padding: 0 20px;
`;

export const Wrap = styled.div`
  max-width: 1400px;
  background: white;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
 
  position: relative;
  margin: 0 auto;
  height: calc(100% - ${headHeight}px);

  &.center {
    text-align: center;
  }
`;

export const CenterWrap = styled.div`
  padding: 20px 0;
  text-align: center;
`;

export const SLink = styled(Link)`
  text-decoration: none;
  color: white;
`;

export default {
  colors,
  fonts,
  SLink,
};
