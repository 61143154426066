import React from "react";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  background-color: #4b6f44;
  color: #fff;
  padding: 40px;
  border: 0;
  margin: 0;

  .head {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
  }

  p {
    text-indent: 40px;
  }

  p.noindent {
    text-indent: 0;
  }

  p.nospace {
    margin-bottom: 0;
  }

  p.notop {
    margin-top: 10px;
  }
`;

function Landing() {
  return (
    <Wrapper>
      <p className="head">The History of The Rock</p>
      <p>
        After King Philip’s War (1675-1678), the stretch of land on which
        Dighton Rock State Park now sits, Assonet Neck, was annexed and became
        part of New Plymouth colony. The colony sold off the land as
        compensation to six local residents, a man by the name of James Walker
        getting the northwest portion on which the as of yet unknown Dighton
        Rock sat. At some point, Rev. John Danforth (1660-1730) caught wind of
        the rock and in 1680 visited and drew it. After passing through the
        hands of Cotton Mather (1663-1728), the drawing made its way to the
        Royal Society of London, with a note claiming the petroglyph depicted
        the coming of foreigners who fought an indigenous tribe, and slew their
        leader, as well as suggesting that hieroglyphs were in use on the rock.
        This vague statement created some confounding questions for early
        antiquarians. Who could these invaders be? Were they the one that
        recorded the stone? Thus, began a 300-year-long tradition of attempting
        to fill that gap. Beyond attribution to Native Americans, the site had
        had many foreign claims. Theories about its provenance immediately began
        to circulate, ranging from Phoenicians, Israelites, Egyptians, and later
        Celts, Norse, and Portuguese.{" "}
      </p>
      <p>
        Due to Dighton Rock’s enigmatic nature, it quickly rose to fame, being
        used for all manner of theories in the burgeoning field of ethnography,
        interpreted as however need fit. Anyone who saw the rock seemed to have
        a different interpretation or saw something wildly different. It is apt
        then, that Edmund Burke Delabarre, the father of the Portuguese theory,
        said that “it is easy to imagine as present on the rock almost any
        desired letter of the alphabet, especially of crude or early forms; and
        that, starting with almost any favored story, he can discover for it, if
        he looks for them eagerly enough, illustrative images to fit its various
        features, and initial letters or even entire words or names.” The fame
        of Dighton Rock did it no good, however. The former status of the rock
        as one of the most famous petroglyphs in the world attracted visitors,
        and inevitably they caused damage to the rock, some in innocent ways,
        chalking, painting, or reengraving the descriptions in attempts to make
        them clearer, others in more negative ways, like engraving their own
        initials or names into its surface. All the while, the rock remained in
        the Taunton River, subject to erosion and repeated winter freezing.{" "}
      </p>
      <p>
        This would change in 1963, when the rock was hoisted by crane, after an
        attempt at dragging it with chains to shore damaged the inscription, to
        be placed upon a newly constructed jetty in its original position.
        Dighton Rock State Park had been founded around the site in 1955. The
        Friends of Dighton Rock Museum, a charitable organization established by
        the medical doctor Manuel Luciano da Silva (1926-2012), and whom was its
        only president until 2011, created a museum on the jetty surrounding the
        rock in 1978, where it still sits today. Dr. da Silva was a proud
        supporter of the Portuguese Corte-Real theory, detailed below, of which
        he had his own version, the details of which are on display within the
        museum. Below are detailed the first iterations of the four main
        theories expounded within the museum. So vast are the number of
        theories, and even greater the versions of them, that a discussion of
        them all is inconceivable on this scale. For theories not detailed in
        the museum, and for versions of theories which are subsequent to the
        originals below, one should peruse the Further Reading section of this
        page.
      </p>
      <p className="head">Indigenous Theory</p>
      <p>
        The theory of the indigeneity of Dighton Rock does not have a single
        progenitor and has remained current through the many iterations of the
        other theories. From the earliest days of its study, when the colonists
        of Massachusetts first encountered the rock after wresting control of
        Assonet Neck in King Phillip’s War, it had been associated with
        indigenous tradition, as per John Danforth’s description. Periodically
        it would be asserted that the rock was Native American in origin, but
        the fervor for other theories continued. In near all of these theories,
        the Indigenous populations were not asked about their understanding of
        the rock, and the potential of their having recorded anything was
        entirely discounted or regarded as insignificant. One attempt at an
        explanation for the petroglyph comes from Henry Rowe Schoolcraft
        (1793-1864), who brought drawings of the rock to the Ojibwe chief
        Shingwauk (1773-1854) to interpret. The Ojibwe, or Chippewa, are an
        Algonquian people, like the Wampanoag, but they are on separate branches
        of that tree, which is something that should be noted when looking at
        the Shingwauk reading. Related roughly through Schoolcraft, Shingwauk
        identified the petroglyph as Native American, and believed it depicted
        the Great Cat and Serpent, who were common cosmological figures among
        the Eastern Woodlands cultures, and that it showed a battle or conflict
        between two tribes of indigenous peoples. He did not, however, talk to
        any native tribes of Massachusetts. Edward Augustus Kendall (1776-1842),
        in his own work where he concluded that the provenance of the rock was
        indigenous, he actually conducted research by interviewing Wampanoag
        tribe members. However, the content of such interviews is unknown as he
        did not publish them. For the indigenous origin, the most likely
        candidate of course is the Wampanoag, being the tribal group of the
        area, and the rock being on land annexed from them, but it is possible
        other tribes could be responsible. The petroglyphs are considered by
        modern scholars to be similar to other petroglyphs throughout Algonquin
        territory. Little had been done in the way of interpreting the rock as a
        Native American symbol, as many such petroglyphs are of an individual
        nature, and while are similar in style mean different things to their
        authors and cultures. To understand the meaning, we would need to speak
        to the, now long gone, individual or individuals who created the
        original inscriptions, or be told the tale by the Wampanoag. However, it
        would seem they do not hold the answer either, as they have been
        divorced from the rock, pushed out of the area, for so long, its meaning
        is no longer recorded in their oral histories, granted that it ever was.
        It is obvious that at one point, Dighton Rock was important to the
        native peoples of the area, for Assonet, the name they gave to the land
        on which the rock was found, means “the place of the stone.” The theory
        of Native American attribution is the Occam’s Razor solution to the
        rock, the simplest answer; that the only people known for certain to be
        in the area at the time of the rock’s creation are the ones who created
        it. This is not, however, mutually exclusive with the other theories –
        if the meaning related by Danforth is true, it could be explained by an
        account of contact with any number of those theorized visitors.
      </p>
      <p className="head">Norse Theory</p>
      <p className="nospace">
        The attribution of Dighton Rock to non-indigenous cultures begins to
        take shape with the works of the Danish historian and antiquarian Carl
        Christian Rafn (1795-1864) and his Icelandic archaeologist counterpart
        Finnur Magnússon (1781-1847) of the Royal Norse Ancient Writings Society
        in Antiquitates Americanae (1837). Their understanding of Dighton Rock
        is one part of a much larger theory about the pre-Colombian settlement
        of North America by the Norse based on the Grœnlendinga Saga (Saga of
        the Greenlanders) and the Eiríks Saga Rauða (Saga of Erik the Red). Rafn
        and Magnússon believed that New England was the true site of Vinland,
        and that the area was settled by Norsemen for an extended period of
        time, eventually mixing with the native population. This included the
        belief that some Old Norse words made their way into the native
        Algonquian languages and influenced place names. In attempting to prove
        his suspicions, Rafn, from his seat in Denmark, reached out to a number
        of American antiquarians to look for evidence of Norse presence. Through
        these numerous correspondences, Rafn was enlightened to the various
        petroglyphs of southern New England, and Dighton Rock in particular. One
        American antiquarian, Thomas Webb, told him that the Rock could not
        possibly be of Native American origin, and indicated that burials and
        mounds in the region, now known to be of indigenous origin, were likely
        Norse. Thomas also told Rafn that the indigenous name for Mount Hope,
        near the mouth of the Taunton River, 12 miles downstream of the rock,
        was Haup. Rafn believed this to be a corruption of the word Hóp, the
        place discovered by Þorfinnr Karlsefni Þórðarson and his crew of 160.
        Backing up the potential of the rock to be Norse was Magnússon’s
        astronomical calculation of the location of Vinland, which used the
        length of a day mentioned in the sagas to calculate its geographical
        position – roughly in the Boston area. Rafn and Magnússon did not just
        rely on circumstances around the rock for their theory, but attempted
        interpretations of the petroglyphs on it as well. One of the traditions
        of the rock is that it depicts a battle between Native Americans and the
        ship-borne foreigners postulated to have left the markings. In the
        sagas, such a battle occurs when Karlsefni and his party find themselves
        interacting with the natives, whom they term skrælings, at Hóp. When a
        bull the Norse brought with them startles an indigenous trading party,
        the skrælings return later and in greater number to attack the
        settlement. To Rafn and Magnússon, the famous horned or antlered
        quadruped on the rock is that bull. Going further, they also find the
        rock to depict individuals from Karlsefni’s family, a shield, and an
        upturned helmet. The horizontal pattern of three “X”s was deemed to be
        part of a series of Latin numerals meaning 151; the number of
        Karlsefni’s crew after the departure of 9 individuals mentioned in the
        sagas. Rafn and Magnússon also claimed that Þorfinnr’s name could be
        made out on the rock in a mix of Latin letters and Norse runes, as well
        as an inscription claiming ownership of the land. They also found
        initials of both Leifr Eiríksson and Tyrkir Suðrmaðr. However, for all
        their evidence, their interpretations were made using drawings of the
        rock, and not actually observing it in person, and the images they
        published of the rock were composites containing added details, which
        they did not disclose.
      </p>
      <p className="notop">
        The discovery of the Viking Age settlement at L'Anse aux Meadows by
        Helge and Anne Stine Ingstad has proven that the saga is, in part, true
        as Rafn suggested, and the Vikings did in fact reach North America
        approximately five centuries prior to Christopher Columbus. This,
        however, is the only currently recognized settlement of Northmen in the
        New World, and so there is still no evidence of large-scale
        colonization, especially not in New England, where Rafn and Magnússon
        placed Vinland. However, just because there is not accepted evidence of
        Norse settlement in New England, that does not mean that none ever
        ventured to the region.
      </p>
      <p className="head">Portuguese Theory</p>
      <p>
        The progenitor of the Portuguese theory is one Edmund Burke Delabarre
        (1863-1945), a Harvard educated psychology professor at Brown
        University. Delabarre spent years studying and working in Europe under
        and alongside notable psychologists, was a pioneer in ink-blot research,
        which involved the phenomenon of pareidolia, the brain’s tendency to
        make images out of and find patterns in indistinct shapes. Beyond this,
        he studied the effects of psychoactive drugs on perception using himself
        as a Guinea pig, although one of his experiments involved an orangutan
        test subject. Delabarre became wise to the rock after purchasing a home
        nearby to it on Assonet Neck. Wandering outside of his field, he became
        something of a historiographer of the rock, an expert in the history of
        writing on the subject. Delabarre wrote a series of three papers for the
        Colonial Society of Massachusetts, detailing the many different
        interpretations of the rock through the years, and in the last
        installment of the series included a section about the psychological
        aspect of these interpretations. While examining a 1907 photograph by
        Charles A. Hathaway to include in the final installment, titled “Recent
        History of Dighton Rock” (1919), he saw a hitherto undetected figure –
        the date “1511,” which, while its lines had been observed and detailed
        before, had not been interpreted as such. Of the much-interpreted
        characters near the center of the rock which are often deemed to be
        alphabetic, Delabarre derived the phrase CORIEIX. Looking further,
        Delabarre then considered that the first I could rather be a T, and so
        the first five letters would be CORTE. Above this word, Delabarre
        discerned the letter M. This lead Delabarre to search for explorers who
        could have been in the region around and prior to the date, and thus to
        the brothers Corte-Real. The brothers Gaspar and Miguel Corte-Real were
        Portuguese explorers, and little is known about them and their exploits.
        According to what few sources regarding them that exist, Gaspar traveled
        to northeastern North America in 1500, and returned with three vessels
        in 1501 where he captured a number of Native Americans. Two of the three
        ships returned to Lisbon, but Gaspar’s ship and crew, laden with the
        majority of the captives, did not return. The following year, Miguel and
        a small fleet set out for North America, and when they arrived split up
        to cover more ground for exploration. Miguel, for whatever reason,
        missed the rendezvous, and the rest of the fleet returned to Portugal
        without him. To Delabarre, it was conceivable that the later Corte-Real,
        stranded in the New World, would attempt to make his way south to
        Spanish territories, ignorant of the true distance. According to
        Delabarre, the journey would have taken some time, potentially due to
        having to negotiate passage with hostile indigenous tribes, and thus
        would explain his presence in Massachusetts nine years after his going
        missing in 1502. His writing could well have been to signal his presence
        so that he could be saved, or to simply leave his mark as he may well
        have been nearing his end from age or illness. After this explanation,
        Delabarre tells that he can see more than just M Corte on the rock, but
        as much as the entire rest of his name, though he admits it could simply
        be his mind filling in the gaps. Delabarre returned to the subject in
        Dighton Rock: A Study of the Written Rocks of New England (1928). After
        having conducted research on the rock, taking images of it with an
        artificial light to show the different characters and figures further
        and more clearly, he revealed what he believed to be a Latin
        inscription, albeit abbreviated, claiming that Corte-Real had become “by
        the will of God, leader of the natives of India in this place.” He has
        also discovered two triangles, on within the other, with a small divot
        in the center, interpreted as the Portuguese coat of arms. This came
        with an expanded version of Corte-Real’s story, in which, after a
        skirmish with a native tribe in which their sachem is killed, he managed
        to maneuver himself into taking over that position.
      </p>
      <p className="head">Phoenician Theory</p>
      <p>
        The theory that it was the Phoenicians who carved the rock was first
        published by a Frenchman by the name of Antoine Court de Gébelin
        (1725-1784). By his former trade, he was trained to be a Protestant
        pastor, but later focused more intently on his interest in esotericism.
        He may be recognized by anyone here familiar with the topic as the
        individual who suggested tarot was more than a simple card game, leading
        it to become a scrying cartomancy exercise. Naturally given his
        disposition and interest and the times, Court de Gébelin became a
        Freemason, his lodge being Les Neuf Sœurs, the same as both Benjamin
        Franklin and Voltaire, who attended contemporaneously. In fact, Franklin
        actually assisted him in his research, acquiring for him a copy of a
        text with which to translate the Lenape language of the Delaware
        natives. Court de Gébelin in his research prioritized searching for the
        monde primitif, or “primitive world,” a golden age of prehistory
        preceding the classical civilizations when there was essentially a
        precursor civilization. Attempting to connect the natives of the New
        World to those of the Old for his theory, Court de Gébelin began to look
        to the Americas, coming into contact with one Stephen Sewall, a Harvard
        educated orientalist who informed him of the theories about the rock,
        one of which was that it was of a Phoenician provenance. Going further,
        Sewall provided pictures of the rock, as well as grammars for Court de
        Gébelin to use in his philological investigation of a potential
        Phoenician–Algonquian link. Court de Gébelin clearly took to the idea of
        Phoenician migration to the Americas to heart, as well as to the idea
        that Dighton Rock was of such origin. In Monde Primitif’s eighth volume
        Court de Gébelin introduced his own reading of the rock based on the
        many pictures he had received from Sewall. Court de Gébelin believed
        that some of the figures on the rock were alphabetical, and so, since
        the indigenous peoples of the region lacked a written language, must
        have had some other source. Examining the figures, Court de Gébelin
        thought them to resemble the letters of the ancient Phoenician alphabet.
        Alternately, they were of Punic origin, the Carthaginians being
        descended from the Phoenicians. In any case, Court de Gébelin thought
        the rock must have been written by lost visitors from the Mediterranean
        and was laden with related symbolism. From the rock, he teased the
        images of Greek and Egyptian gods, from the large figure on the far left
        of the rock, an Oracle with a butterfly on their arm. From the center,
        he managed to find a horse and a beaver, both holding banners, held to
        be representative of the Phoenicians or Carthaginians and the native
        people respectively. Divergent from the opinions of other authors that
        there was some sort of battle scene, Court de Gébelin believed that the
        image depicted friendly relations and a sort of mutual respect. It was
        his opinion that, when read right to left, as Semitic languages are
        written, a story emerges, detailing the crew’s arrival, friendly
        interaction with the natives, and their planned attempt to return to the
        Mediterranean.
      </p>
      <p className="head">Further Reading</p>
      <p className="noindent">
        Delabarre, Edmund Burke. “Recent History of Dighton Rock.” Reprinted
        from The Publications of the Colonial Society of Massachusetts, vol. 20.
        Cambridge, MA: John Wilson and Son, 1919.
      </p>
      <p className="noindent">
        Hunter, Douglas. The Place of Stone: Dighton Rock and the Erasure of
        America's Indigenous Past. Chapel Hill, NC: The University of North
        Carolina Press, 2017.
      </p>
    </Wrapper>
  );
}

export default Landing;
